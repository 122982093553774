import { Instance, SnapshotOut, types } from 'mobx-state-tree';

// ----------------------------------------------------------------------------

/**
 * Model description here for TypeScript hints.
 */
export const SelectoptionsModel = types
  .model('Selectoptions')

  .props({
    value: types.maybeNull(types.string),
    label: types.maybeNull(types.string),
  });

// ----------------------------------------------------------------------------

type SelectoptionsType = Instance<typeof SelectoptionsModel>;
export interface Selectoptions extends SelectoptionsType {}
type SelectoptionsSnapshotType = SnapshotOut<typeof SelectoptionsModel>;
export interface SelectoptionsSnapshot extends SelectoptionsSnapshotType {}
export const createSelectoptionsDefaultModel = () =>
  types.optional(SelectoptionsModel, {
    value: '',
    label: '',
  });
export type SelectoptionsKeys = keyof SelectoptionsSnapshotType & string;
