import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { RulecontentjsonModel, Rulecontentjson } from './../rulecontentjson/rulecontentjson';

// ----------------------------------------------------------------------------

/**
 * Model description here for TypeScript hints.
 */
export const RuleModel = types.model('Rule').props({
  ruleSid: types.union(types.number, types.null),
  rulesetSid: types.union(types.number, types.null),
  rulesetOrd: types.maybe(types.number),
  ruleContentJson: RulecontentjsonModel,
  regSid: types.maybe(types.number),
});

// ----------------------------------------------------------------------------

type RuleType = Instance<typeof RuleModel>;
export interface Rule extends RuleType {}
type RuleSnapshotType = SnapshotOut<typeof RuleModel>;
export interface RuleSnapshot extends RuleSnapshotType {}
export const createRuleDefaultModel = () =>
  types.optional(RuleModel, {
    ruleSid: null,
    rulesetSid: null,
    rulesetOrd: 0,
    ruleContentJson: {} as Rulecontentjson,
    regSid: 0,
  });
export type RuleKeys = keyof RuleSnapshotType & string;
