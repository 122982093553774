import { RuledataModel, Ruledata } from '../../models/ruledata/ruledata';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

// ----------------------------------------------------------------------------

/**
 * Model description here for TypeScript hints.
 */
export const RulecontentjsonModel = types.model('Rulecontentjson').props({
  ruleName: types.optional(types.string, ''),
  ruleType: types.optional(types.string, ''),
  ruleData: RuledataModel,
});
// ----------------------------------------------------------------------------

type RulecontentjsonType = Instance<typeof RulecontentjsonModel>;
export interface Rulecontentjson extends RulecontentjsonType {}
type RulecontentjsonSnapshotType = SnapshotOut<typeof RulecontentjsonModel>;
export interface RulecontentjsonSnapshot extends RulecontentjsonSnapshotType {}
export const createRulecontentjsonDefaultModel = () =>
  types.optional(RulecontentjsonModel, {
    ruleName: '',
    ruleType: '',
    ruleData: {} as Ruledata,
  });
export type RulecontentjsonKeys = keyof RulecontentjsonSnapshotType & string;
