import { Instance, SnapshotOut, types } from 'mobx-state-tree';

// ----------------------------------------------------------------------------

/**
 * Model description here for TypeScript hints.
 */
export const ProcessModel = types.model('Process').props({
  columnName: types.optional(types.string, ''),
  columnType: types.optional(types.string, ''),
  processType: types.optional(types.string, ''),
  categoryList: types.optional(types.array(types.string), []),
  numberMin: types.optional(types.maybeNull(types.string), null),
  numberMax: types.optional(types.maybeNull(types.string), null),
  replaceValue: types.optional(types.string, ''),
});

// ----------------------------------------------------------------------------

type ProcessType = Instance<typeof ProcessModel>;
export interface Process extends ProcessType {}
type ProcessSnapshotType = SnapshotOut<typeof ProcessModel>;
export type ProcessKeys = keyof ProcessSnapshotType & string;
export interface ProcessSnapshot extends ProcessSnapshotType {}
export const createProcessDefaultModel = () =>
  types.optional(ProcessModel, {
    columnName: '', // [NULL/TYPE] '적용컬럼명'
    columnType: '', // [TYPE]'Type' (숫자, 날짜, 카테고리, 숫자 범위)
    processType: '', // [NULL/TYPE] 'Process' (NULL >  그대로 둠, 행삭제, 대체, 직진 행 데이터로 대체....) or (TYPE > 그대로 둠, 강제치환, 행삭제)
    categoryList: [], //[TYPE] 'Validation' columnType 카테고리인 경우 카테고리 배열 =
    numberMin: null, //[TYPE] columnType 숫자범위인 경우 최소값
    numberMax: null, //[TYPE] columnType 숫자범위인 경우 최대값
    replaceValue: '', //[TYPE] processType '강제변환값 설정' 강제치환일 경우 강제변환값 설정
  });
