import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { RuleModel } from '../../models/rule/rule';

export const RulesetModel = types.model('Ruleset').props({
  rulesetSid: types.union(types.number, types.null),
  rulesetNm: types.optional(types.string, ''),
  label: types.optional(types.string, ''),
  regSid: types.maybeNull(types.number),
  ruleList: types.array(RuleModel),
  regDt: types.maybeNull(types.string),
  columnList: types.optional(types.array(types.string), []),
});
// .actions((self) => ({
//   setRulesetNm: (rulesetNm: string) => {
//     self.rulesetNm = rulesetNm;
//   },
//   setLabel: (label: string) => {
//     self.label = label;
//   },
//   setRuleList: (ruleList: Rule[]) => {
//     if (ruleList) self.ruleList.replace(ruleList);
//   },
//   setRegSid: (regSid: number) => {
//     self.regSid = regSid;
//   },
//   setColumnList: (columnList: string[]) => {
//     if (columnList) self.columnList.replace(columnList);
//   },
// }));

type RulesetType = Instance<typeof RulesetModel>;
export interface Ruleset extends RulesetType {}
type RulesetSnapshotType = SnapshotOut<typeof RulesetModel>;
export type RulesetTypeKey = keyof RulesetSnapshotType;
export interface RulesetSnapshot extends RulesetSnapshotType {}
export const createRulesetDefaultModel = () =>
  types.optional(RulesetModel, {
    rulesetSid: null,
    rulesetNm: '',
    label: '',
    regSid: null,
    ruleList: [],
    columnList: [],
  });
