import { Code, CodeModel } from '../../models/code/code';
import {
  createRulesetDefaultModel,
  Ruleset,
  RulesetModel,
  RulesetTypeKey,
} from '../../models/ruleset/ruleset';
import { SelectoptionsModel } from '../../models/selectoptions/selectoptions';
import { RulesetApi } from '../../services';
import { types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';

export const RulesetStoreModel = types
  .model('RulesetStore')
  .props({
    page: types.optional(types.number, 0),
    size: types.optional(types.number, 10),
    rulesetNm: types.optional(types.string, ''),
    rulesetList: types.optional(types.array(RulesetModel), []),
    totalElements: types.optional(types.number, 0),
    rulesetObjectOnModal: createRulesetDefaultModel(),
    isUpdated: types.optional(types.boolean, false),
    isDeleted: types.optional(types.boolean, false),
    isInserted: types.optional(types.boolean, false),
    isDuplicated: types.optional(types.boolean, false),
    deleteList: types.optional(types.array(types.string), []),
    codeContentList: types.optional(types.array(CodeModel), []),
    headerList: types.optional(types.array(types.string), []),
    columnList: types.optional(types.array(SelectoptionsModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setPage: (page: number) => {
      self.page = page;
    },
    setSize: (size: number) => {
      self.size = size;
    },
    setrulesetNm: (rulesetNm: string) => {
      self.rulesetNm = rulesetNm;
    },
    setRulesetList: (rulesetList: Ruleset[]) => {
      self.rulesetList.replace(rulesetList);
    },
    setTotalElements: (totalElements: number) => {
      self.totalElements = totalElements;
    },
    setIsUpdated: (isUpdated: boolean) => {
      self.isUpdated = isUpdated;
    },
    setIsDeleted: (isDeleted: boolean) => {
      self.isDeleted = isDeleted;
    },
    setIsInserted: (isInserted: boolean) => {
      self.isInserted = isInserted;
    },
    setIsDuplicated: (isDuplicated: boolean) => {
      self.isDuplicated = isDuplicated;
    },
    setRulesetObjectOnModal: (props: { [key: string]: any }) => {
      const updated = { ...self.rulesetObjectOnModal, ...props } as Ruleset;
      (Object.keys(updated) as RulesetTypeKey[]).forEach((key) => {
        self.rulesetObjectOnModal[key] = updated[key];
      });
    },
    resetRulesetObjectOnModal: () => {
      self.rulesetObjectOnModal.rulesetSid = null;
      self.rulesetObjectOnModal.rulesetNm = '';
      self.rulesetObjectOnModal.label = '';
      self.rulesetObjectOnModal.regSid = null;
      self.rulesetObjectOnModal.ruleList.replace([]);
    },
    resetDeleteList: () => {
      self.deleteList.clear();
    },
    appendDeleteList: (rulesetSid: string) => {
      self.deleteList.push(rulesetSid);
    },
    setCodeContentList: (codeList: Code[]) => {
      self.codeContentList.replace(codeList);
    },
    resetCodeContentList: () => {
      self.codeContentList.replace([]);
    },
    setHeaderList: (headerList: string[]) => {
      self.headerList.replace(headerList);
    },
    resetHeaderList: () => {
      self.codeContentList.replace([]);
    },
    setColumnList: (headerList: string[]) => {
      const columnOptions: any = [];
      headerList.map((item: any) => {
        columnOptions.push({
          value: item + '',
          label: item + '',
        });
      });
      self.columnList.replace(columnOptions);
    },
    resetColumnList: () => {
      self.columnList.replace([]);
    },
  }))

  //Call API Actions
  .actions((self) => ({
    /**
     * 룰셋 목록 조회
     *
     * @param regSid 로그인 사용자 고유번호
     * @returns
     */
    getRulesetList: async (regSid: number) => {
      const rulesetApi = new RulesetApi(self.environment.api);
      let result = await rulesetApi.getRulesetList(regSid, self.page, self.size, self.rulesetNm);
      console.log('(GET) /api/ruleset ::::::: ' + result.kind);
      if (result.kind === 'ok' || result.kind === 'notOk') {
        if (result.rulesetList !== undefined){
          if(self.isDeleted && result.rulesetList.length == 0){
            return "lastPageisEmpty";
          }
            self.setRulesetList(result.rulesetList);
            self.setTotalElements(result.totalElements);
        }
      }
    },
    /**
     * 룰셋 등록
     */
    insertRuleset: async () => {
      const rulesetApi = new RulesetApi(self.environment.api);
      const result = await rulesetApi.insertRuleset(self.rulesetObjectOnModal);
      console.log('(POST) /api/ruleset ::::::: ' + result.kind);
      if (result.kind === 'ok' || result.kind === 'notOk') {
        if (result.result !== undefined) {
          self.setIsInserted(result.result);
        }
      }
    },
    /**
     * 룰셋 수정
     *
     */
    updateRuleset: async () => {
      const rulesetApi = new RulesetApi(self.environment.api);
      const result = await rulesetApi.updateRuleset(self.rulesetObjectOnModal);
      console.log('(PUT) /api/ruleset ::::::: ' + result.kind);
      if (result.kind === 'ok' || result.kind === 'notOk') {
        if (result.result !== undefined) {
          console.log('isupdated:', result.result);
          self.setIsUpdated(result.result);
        }
      }
    },
    /**
     * 룰셋 삭제
     */
    deleteRuleset: async () => {
      const rulesetApi = new RulesetApi(self.environment.api);
      const result = await rulesetApi.deleteRuleset(self.deleteList);
      console.log('(DELETE) /api/ruleset ::::::: ' + result.kind);
      if (result.kind === 'ok' || result.kind === 'notOk') {
        if (result.result !== undefined) {
          self.setIsDeleted(result.result);
        }
      }
    },
    /**
     * 코드 조회
     *
     * @param cdPid 부모 코드 아이디
     * @param sort 정렬
     */
    getCodeList: async (cdPid: string, sort: string) => {
      const rulesetApi = new RulesetApi(self.environment.api);
      const result = await rulesetApi.getCodeList(cdPid, sort);
      console.log('(GET) /api/commonCd ::::::: ' + result.kind);
      if (result.kind === 'ok' || result.kind === 'notOk') {
        if (result.result !== undefined) {
          self.setCodeContentList(result.result);
        }
      }
    },
    /**
     * 파일 헤더 조회
     *
     * @param file 전송할 파일
     */
    getHeaderList: async (file: File) => {
      const rulesetApi = new RulesetApi(self.environment.api);
      const result = await rulesetApi.getHeaderList(file);
      console.log('(POST) api/ruleset/getHeaderList ::::::: ' + result.kind);
      if (result.kind === 'ok' || result.kind === 'notOk') {
        if (result.result !== undefined) {
          self.setHeaderList(result.result);
        }
      }
    },
  }));