import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree';
import { ProcessModel, Process } from '../../models/process/process';

// ----------------------------------------------------------------------------

/**
 * Model description here for TypeScript hints.
 */
export const RuledataModel = types.model('Ruledata').props({
  thresholdValue: types.optional(types.string, ''),
  processList: types.array(ProcessModel),
  columnNameListforUI: types.optional(types.array(types.string), []),
  processforUI: types.maybeNull(ProcessModel),
});

// ----------------------------------------------------------------------------

type RuledataType = Instance<typeof RuledataModel>;
export interface Ruledata extends RuledataType {}
type RuledataSnapshotType = SnapshotOut<typeof RuledataModel>;
export interface RuledataSnapshot extends RuledataSnapshotType {}
export const createRuledataDefaultModel = () =>
  types.optional(RuledataModel, {
    thresholdValue: '',
    processList: [],
    columnNameListforUI: [],
    processforUI: {} as Process,
  });
export type RuledataKeys = keyof RuledataSnapshotType & string;
