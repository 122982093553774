import { CodeModel, Code } from '../../models/code/code';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

// ----------------------------------------------------------------------------

/**
 * Model description here for TypeScript hints.
 */
export const HistoryModel = types.model('History').props({
  datasetSid: types.union(types.number, types.null),
  orgFileNm: types.optional(types.string, ''),
  rulesetSid: types.union(types.number, types.null),
  pprocsFileSaveTypeCd: types.optional(types.string, ''),
  statusCd: types.optional(types.string, ''),
  pprocsCmpltDt: types.union(types.string, types.null),
  pprocsErrLog: types.union(types.string, types.null),
  pprocsContentData: types.optional(types.string, ''),
  pprocsReport: types.union(types.string, types.null),
  regDt: types.optional(types.string, ''),
  regSid: types.union(types.number, types.null),
  status: CodeModel,
});

// ----------------------------------------------------------------------------

type HistoryType = Instance<typeof HistoryModel>;
export interface History extends HistoryType {}
type HistorySnapshotType = SnapshotOut<typeof HistoryModel>;
export interface HistorySnapshot extends HistorySnapshotType {}
export const createHistoryDefaultModel = () =>
  types.optional(HistoryModel, {
    datasetSid: null,
    orgFileNm: '',
    rulesetSid: null,
    pprocsFileSaveTypeCd: '',
    statusCd: '',
    pprocsCmpltDt: null,
    pprocsErrLog: null,
    pprocsContentData: '',
    pprocsReport: null,
    regDt: '',
    regSid: null,
    status: {} as Code,
  });
export type HistoryKeys = keyof HistorySnapshotType & string;
