import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../../models/extensions/with-environment';
import { HistoryModel, HistorySnapshot } from '../../models/history/history';
import { HistoryApi } from '../../services';

export const HistoryStoreModel = types
  .model('HistoryStore')
  .props({
    historyList: types.optional(types.array(HistoryModel), []),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, 10),
    orgFileNm : types.optional(types.string, ''),
    totalElements: types.optional(types.number, 0),
    isDeleted: types.optional(types.boolean, false),
    isDownloaded: types.optional(types.boolean, false),
    deleteList: types.optional(types.array(types.string), []),
    errorMsg: types.optional(types.string, ''),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  // MUTATEs
  .actions((self) => ({
    setHistoryList: (historySnapshots: HistorySnapshot[]) => {
      self.historyList.replace(historySnapshots);
    },
    setOrgFileNm: (orgFileNm: string) => {
      self.orgFileNm = orgFileNm;
    },
    setPage: (page: number) => {
      self.page = page;
    },
    setSize: (size: number) => {
      self.size = size;
    },
    setTotalElements: (totalElements: number) => {
      self.totalElements = totalElements;
    },
    setIsDeleted: (isDeleted: boolean) => {
      self.isDeleted = isDeleted;
    },
    setIsDownloaded: (isDownloaded: boolean) => {
      self.isDownloaded = isDownloaded;
    },
    setErrorMsg: (errorMsg: string) => {
      self.errorMsg = errorMsg;
    },
    resetDeleteList: () => {
      self.deleteList.clear();
    },
    appendDeleteList: (rulesetSid: string) => {
      self.deleteList.push(rulesetSid);
    },
  }))
  // CALL REST APIs
  .actions((self) => ({
    /**
     * 전처리 내역 조회
     *
     * @param regSid 로그인 사용자 고유번호
     * @returns
     */
    getHistoryList: async (regSid: number) => {
      const historyApi: HistoryApi = new HistoryApi(self.environment.api);
      const result = await historyApi.getHistoryList(regSid, self.page, self.size, self.orgFileNm);
      console.log('(GET) /api/dataset ::::::: ' + result.kind);
      if (result.kind === 'ok' || result.kind === 'notOk') {
        if (result.historyList !== undefined) {
          if (self.isDeleted && result.historyList.length == 0) {
            return "lastPageisEmpty";
          }
          self.setHistoryList(result.historyList);
          self.setTotalElements(result.totalElements);
        }
      }
    },
    /**
     * 전처리 내역 삭제
     */
    deleteHistroy: async () => {
      const historyApi: HistoryApi = new HistoryApi(self.environment.api);
      const result = await historyApi.deleteHistory(self.deleteList);
      console.log('(DELETE) /api/dataset ::::::: ' + result.kind);
      if (result.kind === 'ok') {
        if (result.result !== undefined) {
          self.setIsDeleted(result.result);
        }
      }
    },
    /**
     * 파일 다운로드
     *
     * @param datasetSid 데이터셋 고유 번호
     * @param fileType 파일 타입('original', 'change')
     */
    downloadFile: async (datasetSid: number, fileType: string) => {
      let api = self.environment.api; 
      api.config.timeout = 1000;
      const historyApi: HistoryApi = new HistoryApi(api);
      const result = await historyApi.downloadFile(datasetSid, fileType);
      console.log('(GET) /api/dataset/fileDownload ::::::: ' + result.kind);
      if (result.kind === 'ok' || result.kind === 'notOk') {
        self.setIsDownloaded(result.isDownloaded);
        if (self.isDownloaded == false && result.errorMsg !== undefined) {
          self.setErrorMsg(result.errorMsg);
        }
      }
      else if(result.kind === 'timeout'){
        self.setIsDownloaded(true);
      }
    },
  }));

// ----------------------------------------------------------------------------

type HistoryStoreType = Instance<typeof HistoryStoreModel>;
export interface HistoryStore extends HistoryStoreType { }
type HistoryStoreSnapshotType = SnapshotOut<typeof HistoryStoreModel>;
export interface HistoryStoreSnapshot extends HistoryStoreSnapshotType { }
export const createHistoryStoreDefaultModel = () => types.optional(HistoryStoreModel, {});
export type HistoryStoreKeys = keyof HistoryStoreSnapshotType & string;
