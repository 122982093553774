// routes
import { PATH_AINN } from '../../routes/ainn-paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const sidebarConfig = [
  // PREPROCESS
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: '데이터셋 전처리',
        path: '#',
        icon: ICONS.analytics,
        children: [
          { title: '데이터 업로드 및 전처리', path: PATH_AINN.preprocess.uploadDataset },
          { title: '데이터 전처리 내역 확인', path: PATH_AINN.preprocess.history },
        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: '전처리 규칙 관리',
        path: '#',
        icon: ICONS.user,
        children: [{ title: '내 전처리 룰셋', path: PATH_AINN.management.myRuleset }],
      },
    ],
  },
];

export default sidebarConfig;
