import { useEffect, useState } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
import { RootStore, RootStoreProvider, setupRootStore } from './models';
import { SnackbarProvider } from 'notistack';

export default function App() {
  //
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    (async () => {
      console.log('APP VERION:', process.env.REACT_APP_VERSION);
      setupRootStore().then(setRootStore);
    })();
  }, []);

  if (!rootStore) {
    return null;
  } else {
    // rootStore.userStore.login();
    return (
      <RootStoreProvider value={rootStore}>
        <ThemeConfig>
          <ThemePrimaryColor>
            <RtlLayout>
              <SnackbarProvider>
                <GlobalStyles />
                <ProgressBarStyle />
                <Settings />
                <ScrollToTop />
                <Router />
              </SnackbarProvider>
            </RtlLayout>
          </ThemePrimaryColor>
        </ThemeConfig>
      </RootStoreProvider>
    );
  }
}
