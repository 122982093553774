import { Api, getGeneralApiProblem } from '../../services';
import { ApiResponse } from 'apisauce';
import { AxiosRequestConfig, AxiosProxyConfig } from 'axios';
import { toJS } from 'mobx';
import {
  DeleteHistoryResult,
  DownloadHistoryResult,
  GetHistoryResult,
} from './history-result-types';

// --------------------------------------------------------------------------

/**
 * HistoryApi
 * - async getHistorysResult(): Promise<GetHistorysResult>
 * - async getHistoryResult(id:number): Promise<GetHistoryResult>
 * - async postHistoryResult(history:History): Promise<PostHistoryResult>
 * - async updateHistoryResult(history:History): Promise<UpdateHistoryResult>
 * - async deleteHistoryResult(id:number): Promise<DeleteHistoryResult>
 */
export class HistoryApi {
  private api: Api;


  constructor(api: Api) {
    this.api = api;
  }

  SUB_URL = `/api/dataset`;

  /**
   * 전처리 내역 조회
   *
   * @param regSid 로그인 사용자 고유번호
   * @param page 현재 페이지
   * @param size 페이지 당 데이터 수
   * @returns
   */
  async getHistoryList(regSid: number, page: number, size: number, orgFileNm : string) : Promise<GetHistoryResult> {
    try {
      const url = `/history`;
      const params = {
        regSid: regSid,
        page: page,
        size: size,
        orgFileNm : orgFileNm,
        sort: 'regDt,desc',
      };
      const response: ApiResponse<any> = await this.api.apisauce.get(this.SUB_URL, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) {
          return problem;
        }
      }

      return {
        kind: response.data.error ? 'notOk' : 'ok',
        historyList: response.data.content,
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
      };
    } catch (e) {
      return { kind: 'notOk', totalElements: 0, totalPages: 0 };
    }
  }

/**
 * 전처리 내역 삭제
 *
 * @param deleteList 삭제할 데이터의 datasetSid 배열
 * @returns
 */
  async deleteHistory(deleteList: string[]): Promise<DeleteHistoryResult> {
    try {
      const sendData = {
        deleteIdList: toJS(deleteList),
      };
      const axiosRequestConfig: AxiosRequestConfig = {};
      axiosRequestConfig.headers = { 'content-type': 'application/json' };
      axiosRequestConfig.data = sendData;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        this.SUB_URL,
        payload,
        axiosRequestConfig,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return {
        kind: response.data ? 'notOk' : 'ok',
        result: response.data ? false : true,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

/**
 * 파일 다운로드
 *
 * @param datasetSid 데이터셋 고유 번호
 * @param fileType 파일 타입('original', 'change')
 * @returns
 */
  async downloadFile(datasetSid: number, fileType: string): Promise<DownloadHistoryResult> {
    try {
      const payload = {
        fileType: fileType,
      };
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `${this.SUB_URL}/${datasetSid}/fileDownload`,
        payload,
        {timeout : 100}
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: response.data.error ? 'notOk' : 'ok',
        isDownloaded: response.data.error ? false : true,
        errorMsg: response.data.error ? response.data.exceptionDetail : '',
      };
    } catch (e) {
      return { kind: 'notOk', isDownloaded: false };
    }
  }
}
