import { RulesetSnapshot, useStores } from '../../models';
import {
  GetRulesetDeleteResult,
  GetRulesetInsertResult,
  GetRulesetListResult,
  GetRulesetUpdatedResult,
} from '../../services';
import { ApiResponse } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import { toJS } from 'mobx';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { GetCodeListResult, GetHeaderListResult } from './ruleset-result-types';

export class RulesetApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  SUB_URL = '/api/ruleset';

  /**
   * 룰셋 목록 조회
   *
   * @param regSid 로그인 사용자 고유번호
   * @param page 현재 페이지
   * @param size 페이지 당 데이터 수
   * @returns
   */
  async getRulesetList(regSid: number, page: number, size: number, rulesetNm: string): Promise<GetRulesetListResult> {
    try {
      const sendData = {
        regSid: regSid,
        page: page,
        size: size,
        rulesetNm : rulesetNm,
        sort: 'regDt,desc',
      };

      // this.api.apisauce.setBaseURL('https://preprocapi.surfinn.kr/');
      // this.api.apisauce.setHeader('access-control-allow-origin','*');


      const response: ApiResponse<any> = await this.api.apisauce.get(this.SUB_URL, sendData);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return {
        kind: response.data.error ? 'notOk' : 'ok',
        rulesetList: response.data.content,
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
      };
    } catch (e) {
      return { kind: 'notOk', totalElements: 0, totalPages: 0 };
    }
  }

/**
 * 룰셋 등록
 *
 * @param insertItem 등록할 룰셋 데이터
 * @returns
 */
    async insertRuleset(insertItem: RulesetSnapshot): Promise<GetRulesetInsertResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.post(this.SUB_URL, insertItem);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: response.data.error ? 'notOk' : 'ok',
        result: response.data.error ? false : true,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }


/**
 * 룰셋 수정
 *
 * @param updateItem 수정할 데이터
 * @returns Promise<GetRulesetUpdatedResult>
 */
  async updateRuleset(updateItem: RulesetSnapshot): Promise<GetRulesetUpdatedResult> {
    try {
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `${this.SUB_URL}/${updateItem.rulesetSid}`,
        updateItem,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return {
        kind: response.data.error ? 'notOk' : 'ok',
        result: response.data.error ? false : true,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /**
   * 룰셋 삭제
   *
   * @param deleteList 삭제할 데이터의 rulesetSid 배열
   * @returns Promise<GetRulesetDeleteResult>
   */
  async deleteRuleset(deleteList: string[]): Promise<GetRulesetDeleteResult> {
    try {
      // var formData = new FormData();
      //  deleteList.map((deleteId) =>  formData.append('deleteIdList', deleteId));
      //  formData.forEach((value,key) =>  console.log("key:",key+" :::value:"+value));
      const sendData = {
        deleteIdList: toJS(deleteList),
      };
      const axiosRequestConfig: AxiosRequestConfig = {};
      axiosRequestConfig.headers = { 'content-type': 'application/json' };
      axiosRequestConfig.data = sendData;
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        this.SUB_URL,
        {},
        axiosRequestConfig,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return {
        kind: response.data ? 'notOk' : 'ok',
        result: response.data ? false : true,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /**
   * 코드 조회
   *
   * @param cdPid 코드 부모 아이디
   * @param sort 정렬
   * @returns
   */
  async getCodeList(cdPid: string, sort: string): Promise<GetCodeListResult> {
    try {
      const SUB_URL = '/api/commonCd?cdPid=' + cdPid + '&sort=' + sort;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return {
        kind: response.data.error ? 'notOk' : 'ok',
        result: response.data.content,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }

  /**
   * 파일 헤더 조회
   *
   * @param file 전송할 파일
   * @returns
   */
  async getHeaderList(file: File): Promise<GetHeaderListResult> {
    try {
      const axiosRequestConfig: AxiosRequestConfig = {};
      axiosRequestConfig.headers = { 'Content-Type': 'multipart/form-data;' };
      const formData = new FormData();
      Object.values(file).forEach((singlefile) => formData.append('file', singlefile));
      const SUB_URL = '/api/ruleset/getHeaderList';
      const response: ApiResponse<any> = await this.api.apisauce.post(
        SUB_URL,
        formData,
        axiosRequestConfig,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return {
        kind: response.data.error ? 'notOk' : 'ok',
        result: response.data,
      };
    } catch (e) {
      return { kind: 'notOk' };
    }
  }
}
