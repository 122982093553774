import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import AinnLayout from '../layouts/ainn';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: 'fixed',
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Ainn Routes
    {
      path: 'ainn',
      element: <AinnLayout />,
      children: [
        { element: <Navigate to="/ainn/upload-dataset" replace /> },
        { path: 'my-ruleset-list', element: <AinnMyRuleset /> },
        { path: 'upload-dataset', element: <AinnUploadDataset /> },
        { path: 'history', element: <AinnHistory /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/ainn/upload-dataset" replace />
    //   element: <MainLayout />,
    //   children: [{ element: <LandingPage /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

const NotFound = Loadable(lazy(() => import('../screens/Page404')));

//Ainn Preprocess
const AinnUploadDataset = Loadable(
  lazy(() => import('../screens/upload-dataset/ainn-upload-dataset-screen')),
);
const AinnHistory = Loadable(lazy(() => import('../screens/history/history-screen')));
const AinnMyRuleset = Loadable(lazy(() => import('../screens/my-ruleset/my-ruleset-screen')));

// Main
const LandingPage = Loadable(lazy(() => import('../screens/LandingPage')));
