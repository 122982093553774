// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AINN = '/ainn';

// ----------------------------------------------------------------------

export const PATH_AINN = {
  root: ROOTS_AINN,

  preprocess: {
    uploadDataset: path(ROOTS_AINN, '/upload-dataset'),
    history: path(ROOTS_AINN, '/history'),
  },

  management: {
    myRuleset: path(ROOTS_AINN, '/my-ruleset-list'),
  },
};
