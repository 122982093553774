import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { RulesetStoreModel } from '../../models/ruleset-store/ruleset-store';
import { HistoryStoreModel } from '../../models/history-store/history-store';
import { Ruleset } from '../../models';

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
    rulesetStore: types.optional(RulesetStoreModel, {} as Ruleset),
    historyStore: types.optional(HistoryStoreModel, {} as History)
})
/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
