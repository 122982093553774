import { Instance, SnapshotOut, types } from 'mobx-state-tree';

// ----------------------------------------------------------------------------

/**
 * Model description here for TypeScript hints.
 */
export const CodeModel = types
  .model('Code')

  .props({
    cdId: types.maybe(types.number),
    cdPid: types.maybeNull(types.number),
    cdNm: types.maybe(types.string),
    srcNm: types.maybeNull(types.string),
    cdDispNm: types.maybe(types.string),
    cdDispNmEng: types.maybe(types.string),
    cdOrder: types.maybe(types.number),
    refId: types.maybeNull(types.string),
    dscrpt: types.maybeNull(types.string),
    regDt: types.maybeNull(types.string),
    updDt: types.maybeNull(types.string),
  });

// ----------------------------------------------------------------------------

type CodeType = Instance<typeof CodeModel>;
export interface Code extends CodeType {}
type CodeSnapshotType = SnapshotOut<typeof CodeModel>;
export interface CodeSnapshot extends CodeSnapshotType {}
export const createCodeDefaultModel = () =>
  types.optional(CodeModel, {
    cdId: 0,
    cdPid: 0,
    cdNm: '',
    srcNm: '',
    cdDispNm: '',
    cdDispNmEng: '',
    cdOrder: 0,
    refId: '',
    dscrpt: '',
    regDt: '',
    updDt: '',
  });
export type CodeKeys = keyof CodeSnapshotType & string;
